import React, {useState} from 'react'
import "../style/notes.css"
import "../style/btn-loader.css"
import "../style/modal.css"
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import TriggerTestsStepper from "./TriggerTestsStepper"
import ListItemIcon from '@mui/material/ListItemIcon'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'


const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '16px',
    pt: 6,
    pb: 6,
    pl: 6,
    pr: 6
}


const TriggerTestsModal = () => {

    const [openModal, setOpenModal] = useState(false)
    // eslint-disable-next-line
    const [markedTests, setMarkedTests] = useState({markedScenarioTests: '', markedApiTests: ''})


    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)

    const handleTriggerTestsButtonClick = () => {
        handleOpenModal()
    }


    return <div><ListItemButton
        onClick={() => {
            handleTriggerTestsButtonClick();
        }}>
        <ListItemIcon>
            <PlayCircleOutlineOutlinedIcon style={{color: 'white'}}/>
        </ListItemIcon>
        <ListItemText primary="Trigger A New Test"
                      primaryTypographyProps={{fontFamily: "DM Sans", fontWeight: "bold"}}/>
    </ListItemButton>
        <Modal
            open={openModal}
            onClose={handleCloseModal}
        ><Box sx={modalBoxStyle}>
            <TriggerTestsStepper setMarkedTests={setMarkedTests} setOpenModal={setOpenModal}/>
        </Box>
        </Modal>
    </div>
}


export default TriggerTestsModal