import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const axios = require('axios')
axios.defaults.timeout = 20000


ReactDOM.render((
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>

), document.getElementById('root'));


reportWebVitals(console.log);

