import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import "../style/login-page.css"
import {useSnackbar} from 'notistack'


const Login = (props) => {
    const [userData, setUserData] = useState({username: "", password: ""})
    // eslint-disable-next-line
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const history = useHistory();
    const isAuthenticated = localStorage.getItem("isAuthenticated")


    useEffect(() => {
        if (isAuthenticated) {
            window.location.pathname = "/"
        }
    })

    const handleInputChange = (e) => {
        setUserData((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    const handleSubmit = () => {
        if (userData.username === "") {
            enqueueSnackbar("Username is empty", {
                variant: "warning",
                anchorOrigin: {horizontal: "center", vertical: "top"}
            })
        } else if (userData.password === "") {
            enqueueSnackbar("Password is empty", {
                variant: "warning",
                anchorOrigin: {horizontal: "center", vertical: "top"}
            })
        } else {
            axios.post('https://auth-api.stg.rxd.ai/v1/auth/user/session', {
                "data": {
                    "type": "sessions",
                    "relationships": {
                        "user": {
                            "type": "users",
                            "attributes": {
                                "email": userData.username,
                                "password": userData.password
                            }
                        }
                    }
                },
                "fields": {
                    "sessions": "token"
                }
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.data.attributes.token) {
                    localStorage.setItem("isAuthenticated", "true")
                    history.push("/")
                    window.location.pathname = "/"
                }
            }).catch(function (error) {
                if (error?.response?.status === 401) {
                    enqueueSnackbar("Invalid username or password", {
                        variant: "error",
                        anchorOrigin: {horizontal: "center", vertical: "top"}
                    })
                } else {
                    console.log(error)
                    enqueueSnackbar("Error", {
                        variant: "error",
                        anchorOrigin: {horizontal: "center", vertical: "top"}
                    })
                }
            })
        }
    }


    return (
        <div className="login_container">
            <div className="login_card">
                <h2 className="login_card-heading">
                    Test Dashboard
                    <small>Use your RXD account to login</small>
                </h2>
                <form className="login_card-form">
                    <div className="login_input">
                        <input type="text"
                               className="login_input-field" name="username"
                               onChange={(e) => handleInputChange(e)}
                               required/>
                        <label className="login_input-label">Email</label>
                    </div>
                    <div className="login_input">
                        <input type="password"
                               className="login_input-field" name="password"
                               onChange={(e) => handleInputChange(e)}
                               required/>
                        <label className="login_input-label">Password</label>
                    </div>
                    <div className="login_action">
                        <button
                            type="button"
                            className="login_action-button" name="submitlogin"
                            onClick={handleSubmit}>Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login