import {useState} from "react"
import Tooltip from '@mui/material/Tooltip'


const TableHead = ({columns, handleSorting}) => {

    const [sortField, setSortField] = useState("")
    const [order, setOrder] = useState("asc")


    const handleSortingChange = (accessor) => {
        const sortOrder =
            accessor === sortField && order === "asc" ? "desc" : "asc"
        setSortField(accessor)
        setOrder(sortOrder)
        handleSorting(accessor, sortOrder)
    }


    return (
        <thead>
        <tr>
            {columns.map(({label, accessor, sortable}) => {
                const cl = sortable
                    ? sortField && sortField === accessor && order === "asc"
                        ? "up"
                        : sortField && sortField === accessor && order === "desc"
                            ? "down"
                            : "default"
                    : ""
                return (
                    sortable ?
                        <Tooltip
                            arrow
                            key={accessor}
                            placement={'top'}
                            enterDelay={5}
                            title="Click to sort"
                        >
                            <th
                                key={accessor}
                                onClick={sortable ? () => handleSortingChange(accessor) : null}
                                className={cl}
                            >
                                {label}
                            </th>
                        </Tooltip> : <th
                            key={accessor}
                            onClick={sortable ? () => handleSortingChange(accessor) : null}
                            className={cl}
                        >
                            {label}
                        </th>
                )
            })}
        </tr>
        </thead>
    )
}

export default TableHead;