import {useEffect, useState} from "react"
import TableBody from "./TableBody"
import TableHead from "./TableHead"
import Pagination from "./Pagination"
import "../style/table.css"
import {callApi} from "../api/requests"


const columns = [
    {label: "Test id", accessor: "_id", sortable: true},
    {label: "Test Result", accessor: "test_result", sortable: true},
    {label: "Started At", accessor: "started_at", sortable: true},
    {label: "Finished At", accessor: "finished_at", sortable: true},
    {label: "Triggered From", accessor: "triggered_from", sortable: true},
    {label: "Triggered By", accessor: "triggered_by", sortable: true},
    {label: "Selected Tests", accessor: "selected_tests", sortable: true},
    {label: "Status", accessor: "status", sortable: false},
    {label: "Notes", accessor: "notes", sortable: false}
]


const Table = () => {

    const [tableData, setTableData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage] = useState(10)


    const getTableData = async () => {
        callApi({
            request: "getAllWorkflows", requestParams: {
                query: 7
            }
        }).then((res) => {
            setTableData(res.response.body.data)
        }).catch((err) => {
            console.error(err)
        })
    }

    useEffect(() => {
        getTableData()
    }, [])

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {

            let sorted

            sorted = [...tableData].sort((a, b) => {
                if (!a[sortField]) {
                    return 1
                }
                if (!b[sortField]) {
                    return -1
                }
                if (!a[sortField] && !b[sortField]) {
                    return 0
                }
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                )
            })

            if (sorted) {
                setTableData(sorted)
            }
        }
    }

    //Pagination
    const indexOfLastRecord = currentPage * recordsPerPage
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
    const currentRecords = tableData.slice(indexOfFirstRecord, indexOfLastRecord)
    const nPages = Math.ceil(tableData.length / recordsPerPage)


    return (
        <>
            <table className="dashboard_table">
                <TableHead {...{columns, handleSorting}} />
                <TableBody {...{columns, currentRecords}} />
            </table>
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}

export default Table