import React, {useEffect} from 'react'
import {useHistory} from "react-router-dom"
import "../style/status-button.css"
import DrawerAndAppBar from "../components/DrawerAndAppBar"


const Dashboard = () => {

    const history = useHistory();

    //Prevent going back
    useEffect(() => {
        window.addEventListener("popstate", () => {
            history.go(1)
        })
    })


    return <div>
        <div className="table_container">
            <DrawerAndAppBar/>
        </div>
    </div>
}

export default Dashboard