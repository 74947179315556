const CustomInstance = require("@moodysanalytics/cpm-automation-framework-libs/http/axios/CustomInstance")
const axiosClient = require("@moodysanalytics/cpm-automation-framework-libs/http/axios/client")
const githubUtil = require("@moodysanalytics/cpm-automation-framework-libs/utils/github-util")
const appData = require("@moodysanalytics/cpm-automation-framework-libs/data/app-data.json")
const callWorkflowsApi = new CustomInstance()


const isValidRequestName = (param) => {
    if (!(appData.workflowsApiRequests.includes(param))) {
        throw new Error('Provided request name is invalid')
    }
}


/**
 * @description Call Workflows API without using React state.
 *
 * @param {Object} params
 * @param {String} params.request - One of these: ["getAllWorkflows", "getASingleWorkflow", "createWorkflow", "updateWorkflow", "deleteWorkflow"]
 * @param {Object} [params.requestParams] - Query parameters, form parameters, path parameters, request body
 * @param {Object} [params.requestParams.query] - Query parameters
 * @param {Object} [params.requestParams.form] - Form parameters
 * @param {Object} [params.requestParams.path] - Path parameters
 * @param {Object} [params.requestParams.body] - Request body
 */
export const callApi = async (params) => {

    let response

    try {
        isValidRequestName(params.request)
        callWorkflowsApi.defaults.baseURL = appData.workflowsApiBaseUrl

        // eslint-disable-next-line
        switch (params.request) {
            case "getAllWorkflows":
                response = await axiosClient(callWorkflowsApi, {
                    url: `/workflows`,
                    method: 'GET',
                    params: {
                        'lastNDays': params.requestParams.query,
                    }
                })
                break
            case "getASingleWorkflow":
                response = await axiosClient(callWorkflowsApi, {
                    url: `/workflows/${params.requestParams.path}`,
                    method: 'GET'
                })
                break
            case "createWorkflow":
                response = await axiosClient(callWorkflowsApi, {
                    url: `/workflows`,
                    method: 'POST',
                    data: params.requestParams.body
                })
                break
            case "updateWorkflow":
                response = await axiosClient(callWorkflowsApi, {
                    url: `/workflows/${params.requestParams.path}`,
                    method: 'PUT',
                    data: params.requestParams.body
                })
                break
            case "deleteWorkflow":
                response = await axiosClient(callWorkflowsApi, {
                    url: `/workflows/${params.requestParams.path}`,
                    method: 'DELETE'
                })
                break
        }
    } catch (err) {
        console.error(err)
    }

    return response
}


/**
 * @param {Object} options
 * @param {String} options.owner
 * @param {String} options.repo
 * @param {String} options.event_type
 * @param {String} [options.client_payload]
 *
 * @see https://docs.github.com/en/rest/repos/repos#create-a-repository-dispatch-event
 */
export const createGithubActionsRepositoryDispatchEvent = async (options) => {

    return githubUtil.createRepositoryDispatchEvent(process.env.REACT_APP_GITH_TOKEN, {
        owner: options.owner,
        repo: options.repo,
        event_type: options.event_type,
        client_payload: options.client_payload
    })
}