import React, {useState} from "react"
import {useSnackbar} from 'notistack'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import TestListCheckboxes from "./TestListCheckboxes"
import {callApi, createGithubActionsRepositoryDispatchEvent} from "../api/requests"
const appData = require("@moodysanalytics/cpm-automation-framework-libs/data/app-data.json")


const steps = ['Name your test run', 'Select and run the tests']


export default function TriggerTestsStepper(props) {

    // eslint-disable-next-line
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [activeStep, setActiveStep] = React.useState(0)
    const [skipped, setSkipped] = React.useState(new Set())
    const [textInput, setTextInput] = useState('')
    const [markedTests, setMarkedTests] = useState({markedScenarioTests: '', markedApiTests: ''})


    const displaySkipButton = false

    const isStepOptional = (step) => {
        return step === 0
    }

    const isStepSkipped = (step) => {
        return skipped.has(step)
    }

    const handleNext = async () => {
        let newSkipped = skipped
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values())
            newSkipped.delete(activeStep)
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped)

        if (activeStep === steps.length - 1) {

            props.setOpenModal(false)

            await callApi({
                request: "createWorkflow",
                requestParams: {
                    url: `/workflows`,
                    method: 'POST',
                    data: {
                        "test_result": "pending",
                        "started_at": "",
                        "finished_at": "",
                        "triggered_from": "Test Dashboard",
                        "triggered_by": "",
                        "initiator_pr_head_commit": "",
                        "initiator_pr_title": "",
                        "link_to_test": "https://github.com/moodysanalytics/cpm-automation-framework/actions",
                        "selected_tests": markedTests.markedScenarioTests + " " + markedTests.markedApiTests,
                        "status": "",
                        "notes": ""
                    }
                }
            }).then((res) => {
                if (res.response.body.insertedId) {
                    enqueueSnackbar('Tests trigger queued successfully', {variant: "success"})

                    createGithubActionsRepositoryDispatchEvent({
                        owner: appData.repositoryOwner,
                        repo: "cpm-automation-framework",
                        event_type: textInput || res.response.body.insertedId,
                        client_payload: {
                            CUCUMBER_CYPRESS_TESTS: JSON.stringify(markedTests.markedScenarioTests) === "\"\"" ? '' : JSON.stringify(markedTests.markedScenarioTests),
                            JEST_TESTS: JSON.stringify(markedTests.markedApiTests) === "\"\"" ? '' : JSON.stringify(markedTests.markedApiTests),
                            DASHBOARD_TEST_ID: res.response.body.insertedId,
                            TRIGGERED_BY_DASHBOARD: "true"
                        }
                    }).then(res => {
                        if (res && res.status === 204) {
                            enqueueSnackbar('Tests triggered successfully', {variant: "success"})
                        } else {
                            enqueueSnackbar("Error while triggering the tests", {
                                variant: "error",
                                anchorOrigin: {horizontal: "center", vertical: "top"}
                            })
                        }
                    })
                } else {
                    enqueueSnackbar("Error while queueing the test trigger", {
                        variant: "error",
                        anchorOrigin: {horizontal: "center", vertical: "top"}
                    })
                }
                return res.response.body.insertedId
            }).catch(function (error) {
                console.error(error)
                enqueueSnackbar("Error while triggering the tests", {
                    variant: "error",
                    anchorOrigin: {horizontal: "center", vertical: "top"}
                })
            })
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.")
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values())
            newSkipped.add(activeStep)
            return newSkipped
        })
    }

    // eslint-disable-next-line
    const handleReset = () => {
        setActiveStep(0)
    }

    const handleTextInputChange = event => {
        setTextInput(event.target.value)
    }


    return (
        <Box sx={{width: '100%'}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        )
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label}
                              {...stepProps}
                              sx={{
                                  '& .MuiStepLabel-root .Mui-completed': {
                                      color: '#363853',
                                  },
                                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                      {
                                          color: 'grey.500',
                                      },
                                  '& .MuiStepLabel-root .Mui-active': {
                                      color: '#363853',
                                  },
                                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                      {
                                          color: 'common.white',
                                      },
                                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                      fill: 'common.white',
                                  }
                              }}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <React.Fragment>
                {activeStep === 0 ?
                    (<Box sx={{mt: 4, mb: 2}}><Typography variant="h6" display="inline">Name your test
                        run{" "}</Typography>
                        <Typography variant="caption" display="inline">Optional</Typography></Box>) :
                    (<Typography variant="h6" sx={{mt: 4, mb: 2}}>Select the tests you want to run</Typography>)}

                {activeStep === 0 ?
                    (<Tooltip
                        placement={'right'}
                        enterDelay={5}
                        title="The value entered here will appear as workflow name on Github Actions. Default is test id"
                    ><TextField
                        size="small"
                        value={textInput}
                        onChange={handleTextInputChange}
                        helperText="Max 100 characters"
                        variant="outlined"
                        sx={{mt: 2, mb: 21.28}}/></Tooltip>) :
                    (<TestListCheckboxes setMarkedTests={setMarkedTests}/>)}
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 7}}>
                    <Button
                        variant="outlined"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{
                            textTransform: 'none',
                            width: "180px",
                            borderColor: "#363853",
                            color: "#363853",
                            '&:hover': {
                                color: "#6c6c9a",
                                borderColor: '#6c6c9a'
                            }
                        }}
                    >
                        Back
                    </Button>
                    {isStepOptional(activeStep) && displaySkipButton && (
                        <Button variant="outlined" sx={{
                            textTransform: 'none',
                            width: "180px",
                            borderColor: "#363853",
                            color: "#363853",
                            '&:hover': {
                                color: "#6c6c9a",
                                borderColor: '#6c6c9a'
                            }
                        }} onClick={handleSkip}>
                            Skip
                        </Button>
                    )}
                    <Box sx={{flex: '1 1 auto'}}/>
                    <Button variant="contained" sx={{
                        textTransform: 'none',
                        width: "180px",
                        color: "white",
                        backgroundColor: "#363853",
                        borderColor: "grey",
                        '&:hover': {
                            backgroundColor: '#47476b'
                        }
                    }}
                            onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Run Tests' : 'Next Step'}
                    </Button>
                </Box>
            </React.Fragment>
        </Box>
    )
}
