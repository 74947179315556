import React, {useState} from 'react'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import ListItemIcon from '@mui/material/ListItemIcon'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Table from "./Table"
import TriggerTestsModal from "./TriggerTestsModal"
import appData from "@moodysanalytics/cpm-automation-framework-libs/data/app-data.json"


const drawerWidth = 270


const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
)

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))


export default function DrawerAndAppBar() {

    const theme = useTheme()
    const [open, setOpen] = useState(false)


    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleLogout = () => {
        localStorage.clear();
        window.location.pathname = "/login"
    }


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                sx={{
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "none",
                }}
                position="fixed"
                open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: 'none'})}}
                    >
                        <Tooltip
                            placement={'bottom'}
                            enterDelay={5}
                            title={<span>Click to open the menu</span>}
                        >
                            <MenuIcon sx={{
                                color: "#363853", ":hover": {
                                    color: '#a3a3c2'
                                }
                            }}/>
                        </Tooltip>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" fontFamily="DM Sans">
                        Test Status
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: "#363853",
                        color: "white"
                    }
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <Tooltip
                            placement={'bottom'}
                            enterDelay={5}
                            title={<span>Click to close</span>}
                        ><ChevronLeftIcon sx={{
                            color: "white", ":hover": {
                                color: '#a3a3c2'
                            }
                        }}/></Tooltip> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    <ListItem key="trigger_a_test"
                              sx={{
                                  '&.MuiListItem-root:hover': {
                                      backgroundColor: '#242434'
                                  }
                              }}>
                        <TriggerTestsModal/>
                    </ListItem>
                    <ListItem key="tests_list"
                              sx={{
                                  '&.MuiListItem-root:hover': {
                                      backgroundColor: '#242434'
                                  }
                              }}>
                        <ListItemButton
                            onClick={() => window.open(appData.resultsPageLink, "_blank")}>
                            <ListItemIcon>
                                <FactCheckOutlinedIcon style={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Results Page"
                                          primaryTypographyProps={{fontFamily: "DM Sans", fontWeight: "bold"}}/>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem key="logout"
                              sx={{
                                  '&.MuiListItem-root:hover': {
                                      backgroundColor: '#242434'
                                  }
                              }}>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutOutlinedIcon style={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Logout"
                                          primaryTypographyProps={{fontFamily: "DM Sans", fontWeight: "bold"}}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader/>
                <Table/>
            </Main>
        </Box>
    )
}
