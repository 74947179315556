import React, {useEffect, useState} from 'react'
import "../style/notes.css"
import "../style/btn-loader.css"
import "../style/modal.css"
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {callApi} from "../api/requests"
import {useSnackbar} from 'notistack'


const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 468,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '16px',
    p: 4,
}


const Notes = (props) => {

    const initialNotesState = props.data.notes ? "unread" : "read"
    const [notesState, setNotesState] = useState(
        localStorage.getItem("note-state_" + props.data._id) || initialNotesState)
    const [openModal, setOpenModal] = useState(false)
    const [notesValue, setNotesValue] = useState('Controlled')
    const [submitButtonState, setSubmitButtonState] = useState("")
    // eslint-disable-next-line
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()


    const handleOpenModal = () => setOpenModal(true)

    const handleCloseModal = () => setOpenModal(false)

    function handleStatusChange() {
        setNotesState("btn_loader")
    }

    function handleNotesValueChange(event) {
        setNotesValue(event.target.value);
    }

    function handleSubmit() {
        setSubmitButtonState("clicked" + Math.random())
    }

    useEffect(() => {
        if (props.clickedRowTestId !== -1) { //Prevent initial execution in the first render
            localStorage.setItem("note-state_" + props.clickedRowTestId, "read")
            setNotesState("read")
        }
    }, [notesState])

    useEffect(() => {
        if (props.clickedRowTestId !== -1) {
            callApi({
                request: "updateWorkflow", requestParams: {
                    path: props.clickedRowTestId,
                    body: {"notes": notesValue === "Controlled" ? "" : notesValue}
                }
            }).then(res => {
                if (res.response.statusText === "OK" && res.response.body.message && !res.response.body.message.toLowerCase().includes("error")) {
                    enqueueSnackbar('Notes saved', {variant: "success"})
                } else {
                    enqueueSnackbar("Error: Couldn't save the note", {
                        variant: "error",
                        anchorOrigin: {horizontal: "center", vertical: "top"}
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }, [submitButtonState])


    if (notesState === "unread") {
        return <div>
            <button className="unread" onClick={handleOpenModal}></button>
            <Modal
                open={openModal}
                onClose={() => {
                    handleCloseModal();
                    handleStatusChange();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            ><Box sx={modalBoxStyle}>
                <div id="modal-modal-title">
                    Notes<br/><br/>
                </div>
                <div>
                    <TextField
                        id="outlined-multiline-static"
                        helperText="Your notes will be seen by others"
                        defaultValue={props.data.notes}
                        sx={{width: "400px"}}
                        placeholder={"Enter your notes"}
                        multiline
                        rows={5}
                        onChange={handleNotesValueChange}
                    /><br/><br/>
                    <Button variant="contained" sx={{
                        textTransform: 'none',
                        float: "right",
                        transform: "translateX(-52%)",
                        position: "relative",
                        width: "200px",
                        color: "white",
                        backgroundColor: "#363853",
                        borderColor: "grey",
                        '&:hover': {
                            backgroundColor: '#47476b'
                        }
                    }}
                            onClick={() => {
                                handleSubmit();
                                handleStatusChange();
                                handleCloseModal();
                            }}>Save</Button>
                </div>
            </Box>
            </Modal>
        </div>
    } else {
        return <div>
            <button className={notesState} onClick={handleOpenModal}></button>
            <Modal
                open={openModal}
                onClose={() => {
                    handleCloseModal();
                    handleStatusChange();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            ><Box sx={modalBoxStyle}>
                <div id="modal-modal-title">
                    Notes<br/><br/>
                </div>
                <div>
                    <TextField
                        id="outlined-multiline-static"
                        helperText="Your notes will be seen by others"
                        defaultValue={props.data.notes}
                        sx={{width: "400px", borderColor: "#363853"}}
                        placeholder={"Enter your notes"}
                        multiline
                        rows={5}
                        onChange={handleNotesValueChange}
                    /><br/><br/>
                    <Button variant="contained" sx={{
                        textTransform: 'none',
                        float: "right",
                        transform: "translateX(-52%)",
                        position: "relative",
                        width: "200px",
                        color: "white",
                        backgroundColor: "#363853",
                        borderColor: "grey",
                        '&:hover': {
                            backgroundColor: '#47476b'
                        }
                    }}
                            onClick={() => {
                                handleSubmit();
                                handleStatusChange();
                                handleCloseModal();
                            }}>Save</Button>
                </div>
            </Box>
            </Modal>
        </div>
    }
}


export default Notes