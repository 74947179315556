import React, {useState, useEffect} from "react"
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import ListSubheader from '@mui/material/ListSubheader'
import "../style/testlist-checkboxes.css"
import appData from "@moodysanalytics/cpm-automation-framework-libs/data/app-data.json"


const TestListCheckboxes = (props) => {

    const [checked, setChecked] = useState([])
    const scenarioTestsCheckList = appData.scenarioTestTags
    const apiTestsCheckList = appData.apiTestFiles

    const handleCheck = (event) => {
        let updatedList = [...checked]
        if (event.target.checked) {
            updatedList = [...checked, event.target.parentElement.attributes.label.value]  //Because it is MUI
        } else {
            updatedList.splice(checked.indexOf(event.target.parentElement.attributes.label.value), 1)
        }
        setChecked(updatedList)
    }

    let checkedItemsScenarioTests = []
    let checkedItemsApiTests = []

    for (const element of checked) {
        element.includes("@") ? checkedItemsScenarioTests.push(element) : checkedItemsApiTests.push(element)
    }

    checkedItemsScenarioTests = checkedItemsScenarioTests.join(" or ")
    checkedItemsApiTests = checkedItemsApiTests.join('')

    useEffect(() => {
        props.setMarkedTests({
            markedScenarioTests: checkedItemsScenarioTests,
            markedApiTests: checkedItemsApiTests
        })
    }, [checked])


    let isChecked = (item) =>
        checked.includes(item) ? "checklist-checked-item" : "checklist-not-checked-item"


    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid style={{maxHeight: 250, overflow: 'auto'}} item sx={{ml: 1}}>
                    <List sx={{width: '100%', maxWidth: 500, bgcolor: 'background.paper'}}
                          subheader={<ListSubheader component="div">Scenario Tests</ListSubheader>}
                          dense>
                        {scenarioTestsCheckList.map((value) => (
                            <ListItem
                                disableGutters
                                key={value}
                                secondaryAction={
                                    <Tooltip
                                        arrow
                                        placement={'right'}
                                        enterDelay={5}
                                        title="Click for test info"
                                    >
                                        <IconButton aria-label="test-info"
                                                    onClick={() => window.open(appData.scenarioTestLinks.firstPart + value.replace("@", "") + appData.scenarioTestLinks.secondPart, "_blank")}>
                                            <InfoOutlinedIcon sx={{fontSize: "large", color: "#467fd0"}}/>
                                        </IconButton></Tooltip>}>
                                <div>
                                    <Checkbox label={value} onChange={handleCheck} sx={{
                                        '&.Mui-checked': {
                                            color: "#363853"
                                        }
                                    }}/>
                                    <span className={isChecked(value)}>{value}</span>
                                </div>
                            </ListItem>))} </List>
                </Grid>
                <Grid item style={{maxHeight: 500, overflow: 'auto'}} sx={{ml: 15}}>
                    <List sx={{width: '100%', maxWidth: 500, bgcolor: 'background.paper'}}
                          subheader={<ListSubheader component="div">API Tests</ListSubheader>}
                          dense>
                        {apiTestsCheckList.map((value) => (
                            <ListItem
                                disableGutters
                                key={value}
                                secondaryAction={
                                    <Tooltip
                                        arrow
                                        placement={'right'}
                                        enterDelay={5}
                                        title="Click for test info"
                                    >
                                        <IconButton aria-label="test-info"
                                                    onClick={() => window.open(appData.apiTestLinks.firstPart + value.replace(" -- ", ""), "_blank")}>
                                            <InfoOutlinedIcon sx={{fontSize: "large", color: "#467fd0"}}/>
                                        </IconButton></Tooltip>}>
                                <div>
                                    <Checkbox label={value} onChange={handleCheck} sx={{
                                        '&.Mui-checked': {
                                            color: "#363853"
                                        }
                                    }}/>
                                    <span className={isChecked(value)}>{value}</span>
                                </div>
                            </ListItem>))} </List>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TestListCheckboxes