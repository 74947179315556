import React from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'


const Pagination = ({nPages, currentPage, setCurrentPage}) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
    }


    return (
        <Stack direction="row"
               justifyContent="flex-end"
               alignItems="flex-end"
               spacing={0.1}>
            <IconButton aria-label="previous"
                        style={{maxWidth: '20px', minWidth: '20px'}}
                        onClick={prevPage}
                        sx={{color: "#3d3d3d"}}>
                <ChevronLeftOutlinedIcon/>
            </IconButton>
            {pageNumbers.map(pgNumber => (
                <Button
                    key={pgNumber}
                    style={{maxWidth: '20px', minWidth: '20px'}}
                    sx={{fontSize: "medium", color: "#3d3d3d"}}
                    onClick={() => setCurrentPage(pgNumber)}>
                    {pgNumber}
                </Button>
            ))}
            <IconButton aria-label="next"
                        style={{maxWidth: '20px', minWidth: '20px'}}
                        onClick={nextPage}
                        sx={{color: "#3d3d3d"}}>
                <ChevronRightOutlinedIcon/>
            </IconButton>
        </Stack>
    )
}

export default Pagination