import React, {useState, useCallback} from 'react'
import StatusButton from "./StatusButton"
import "../style/testresult-text.css"
import Notes from "./Notes"
import dateTimeUtil from "@moodysanalytics/cpm-automation-framework-libs/utils/datetime-util";


const TableBody = (props) => {

    const [clickedRowTestId, setClickedRowTestId] = useState(-1)

    const handleRowClick = useCallback((event) => {
        const id = event.currentTarget.cells[0].innerText
        setClickedRowTestId(id)
    }, [])

    const updateUnixEpochDates = () => {
        for (let i = 0; i < props.currentRecords.length; i++) {
            if (typeof props.currentRecords[i]["started_at"] == "number") {
                props.currentRecords[i]["started_at"] = dateTimeUtil.unixToHumanDate(props.currentRecords[i]["started_at"], "DD/MM/YYYY HH:mm:ss")
            }

            if (typeof props.currentRecords[i]["finished_at"] == "number") {
                props.currentRecords[i]["finished_at"] = dateTimeUtil.unixToHumanDate(props.currentRecords[i]["finished_at"], "DD/MM/YYYY HH:mm:ss")
            }
        }

        return props.currentRecords
    }


    return (
        <tbody>
        {updateUnixEpochDates().map((data) => {
            return (
                <tr key={data._id ? data._id : "temp-" + Math.random()}
                    onClick={handleRowClick}>
                    {props.columns.map(({accessor}) => {
                        let tData = data[accessor] ? data[accessor] : "-"
                        tData = accessor === "status" ? <StatusButton {...{data, clickedRowTestId}}/> : tData
                        tData = accessor === "notes" ? <Notes {...{data, clickedRowTestId}}/> : tData
                        if (accessor === "test_result") {
                            tData = data[accessor] === "success" ?
                                <div><a href={data?.link_to_test} className="testresult_link_success">Success</a></div>
                                : data[accessor] === "failed" ?
                                    <a href={data?.link_to_test} className="testresult_link_failed">Failed</a> :
                                    <a href={data?.link_to_test} className="testresult_link_pending">Pending</a>
                        }
                        return <td key={accessor}>{tData}</td>;
                    })}
                </tr>
            )
        })}
        </tbody>
    )
}

export default TableBody