import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './style/app.css';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import ProtectedRoute from "./components/ProtectedRoute";
import {SnackbarProvider} from 'notistack';


class App extends Component {

    render() {
        const App = () => (
            <div>
                <Switch>
                    <Route exact path='/login' component={Login}/>
                    <ProtectedRoute path='/' component={Dashboard}/>
                </Switch>
            </div>
        )

        return (
            <SnackbarProvider maxSnack={3}>
                <Switch>
                    <App/>
                </Switch>
            </SnackbarProvider>
        )
    }
}

export default App;