import React, {useState, useEffect} from 'react'
import "../style/status-button.css"
import "../style/btn-loader.css"
import {callApi} from "../api/requests"
import {useSnackbar} from 'notistack'
import Tooltip from '@mui/material/Tooltip'


const StatusButton = (props) => {

    const [statusButtonState, setStatusButtonState] = useState("button_status_failed")
    // eslint-disable-next-line
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()


    function handleStatusChange() {
        setStatusButtonState("btn_loader")
    }

    useEffect(() => {
        props.data.status === "acknowledged" ? setStatusButtonState("button_status_success") : setStatusButtonState("button_status_failed")
    }, [props.data.status])

    useEffect(() => {

        console.log(props.clickedRowTestId)
        if (props.clickedRowTestId !== -1) { //Prevent unnecessary calls in the first render
            callApi({
                request: "updateWorkflow", requestParams: {
                    path: props.clickedRowTestId,
                    body: {"status": "acknowledged"}
                }
            }).then(res => {
                if (res.response.message === "Workflow updated successfully") {
                    setStatusButtonState("button_status_success")
                    enqueueSnackbar('Workflow status successfully updated', {variant: "success"})
                } else {
                    setStatusButtonState("button_status_failed")
                    enqueueSnackbar("Error: Couldn't update the workflow status", {
                        variant: "error",
                        anchorOrigin: {horizontal: "center", vertical: "top"}
                    })
                }
            })
        }
    }, [statusButtonState])


    switch (props.data?.test_result) {
        case "success":
            return <div><Tooltip
                placement={'left'}
                arrow
                enterDelay={5}
                title={<span style={{color: 'green'}}>Success</span>}
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            fontSize: "80%",
                        },
                    },
                    arrow: {
                        sx: {
                            color: "white"
                        },
                    }
                }}
            >
                <button type="button" className="button_status_success"></button>
            </Tooltip>
            </div>
        case "pending":
            return <div><Tooltip
                placement={'left'}
                arrow
                enterDelay={5}
                title={<span style={{color: 'grey'}}>Pending</span>}
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            fontSize: "80%",
                        },
                    },
                    arrow: {
                        sx: {
                            color: "white"
                        },
                    }
                }}
            >
                <button type="button" className="button_status_pending"></button>
            </Tooltip>
            </div>
        case "failed":
            if (statusButtonState === "button_status_success") {
                return <div><Tooltip
                    placement={'left'}
                    arrow
                    enterDelay={5}
                    title={<span style={{color: 'green'}}>Acknowledged</span>}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'white',
                                fontSize: "80%",
                            },
                        },
                        arrow: {
                            sx: {
                                color: "white"
                            },
                        }
                    }}
                >
                    <button type="button" className="button_status_success"></button>
                </Tooltip>
                </div>
            } else {
                return <div><Tooltip
                    placement={'left'}
                    arrow
                    enterDelay={5}
                    title={<span
                        style={{color: "red"}}>This test has failed. <strong>Click<br/> &emsp;&nbsp;to acknowledge</strong></span>}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'white',
                                fontSize: "80%",
                            },
                        },
                        arrow: {
                            sx: {
                                color: "white"
                            },
                        }
                    }}
                >
                    <button className={statusButtonState} onClick={handleStatusChange}></button>
                </Tooltip>
                </div>
            }
        default:
            return <div><Tooltip
                placement={'left'}
                arrow
                enterDelay={5}
                title={<span style={{color: 'grey'}}>Pending</span>}
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            fontSize: "80%",
                        },
                    },
                    arrow: {
                        sx: {
                            color: "white"
                        },
                    }
                }}
            >
                <button type="button" className="button_status_pending"></button>
            </Tooltip>
            </div>
    }
}

export default StatusButton